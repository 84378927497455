import { addFakePaymentInput, domReady } from './helpers';

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

import { Application } from '@hotwired/stimulus';
import AxiosFormController from './controllers/axios-form-controller';
import ModalController from './controllers/modal-controller';
import ExpandableController from './controllers/expandable-controller';
import LoadController from './controllers/load-controller';
import CollectionFilterController from './controllers/collection-filter-controller';
import ReviewFromController from './controllers/review-form-controller';
import StarController from './controllers/stars-controller';
import NoticeController from './controllers/notices-validation-controller';
import CartPickupController from './controllers/cart/cart-pickup-controller';

window.Stimulus = Application.start();

Stimulus.register('axios-form', AxiosFormController);
Stimulus.register('modal', ModalController);
Stimulus.register('expandable', ExpandableController);
Stimulus.register('load-data', LoadController);
Stimulus.register('collection-filter', CollectionFilterController);
Stimulus.register('review-form', ReviewFromController);
Stimulus.register('stars', StarController);
Stimulus.register('notice-validation', NoticeController);
Stimulus.register('cart-pickup', CartPickupController);

domReady(() => {
    addFakePaymentInput();
});
