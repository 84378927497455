import {Controller} from '@hotwired/stimulus';

const idsToValidate = [
    'billing_address_1',
    'shipping_address_1',
    'billing_postcode',
    'billing_company',
    'billing_company_code',
    'billing_company_vat_code',
    'shipping_shop_pickup',
];

export default class extends Controller {
    static get targets() {
        return ['notices'];
    }

    connect() {
        for (const id of idsToValidate) {
            const container = document.getElementById(id + '_field');
            if (container) {
                const notice = this.element.querySelector('[data-id=' + id + ']');
                const error = container.querySelector('.validation-error');
                let errorContent = '';

                if (notice) {
                    container.classList.add('woocommerce-validation-invalid');
                    errorContent = notice.innerHTML;
                } else {
                    container.classList.remove('woocommerce-validation-invalid');
                }

                if (error) {
                    error.innerHTML = errorContent;
                }
            }
        }
    }
}