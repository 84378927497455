import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    connect() {
        if (this.element.hasAttribute('data-attach-to') && this.element.hasAttribute('data-action')) {
            this.currentLanguageCode = document.documentElement.lang.split('-')[0];
            this.element.addEventListener('click', async (e) => {
                const container = document.getElementById(this.element.getAttribute('data-attach-to'));
                e.preventDefault();

                if (container.classList.contains('loading')) {
                    return;
                }
                container.innerHTML = '';
                container.classList.add('loading');

                if (container) {
                    const url = this.element.getAttribute('data-action')
                        + '?rest_language=' + this.currentLanguageCode
                        + '&search=' + this.element.getAttribute('data-search');

                    let response;

                    try {
                        response = await axios.get(url);

                        response = response.data.data;
                    } catch (error) {
                        response = error.response.data.data;
                    }

                    if (response && response.success) {
                        container.classList.remove('loading');
                        container.innerHTML = response.data;
                    } else {
                        this.setupFormUnexpectedError(container);
                    }
                }
            });
        }
    }

    setupFormUnexpectedError(container) {
        const messageNode = document.createElement('span');
        messageNode.classList.add('general-error');
        messageNode.innerHTML = document.querySelector('meta[name="global_axios_validator_error"]').content;
        container.appendChild(messageNode);
        container.classList.remove('loading');
    }
}
