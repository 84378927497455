import {Controller} from '@hotwired/stimulus';

export default class extends Controller {

    setupFormAction(action, actionData) {
        this._actionData = actionData;
        switch (action) {
            case 'some_custom_action':

                break;
        }
    }

    connect() {
        this.currentLanguageCode = document.documentElement.lang.split('-')[0];
        this.element.addEventListener('submit', async (e) => {
            e.preventDefault();

            if (this.element.classList.contains('loading')) {
                return;
            }

            this.element.classList.add('loading');

            const url = this.element.action + '?rest_language=' + this.currentLanguageCode;
            const method = this.element.method.toLowerCase();
            const data = new FormData(this.element);

            let response;

            try {
                if (method === 'get') {
                    response = await axios.get(url);
                } else if (method === 'post') {
                    response = await axios.post(url, data, {headers: {'Content-Type': 'multipart/form-data'}});
                }

                response = response.data.data;
            } catch (error) {
                response = error.response.data.data;
            }

            this.removeFormNotes();

            if (response && response.errors && Object.keys(response.errors).length) {
                this.setupFormErrors(response.errors);

            } else if (response && response.general_error) {
                this.setupFormGeneralError(response.general_error);

            } else if (response && response.general_success) {
                this.setupFormGeneralSuccess(response.general_success);

            } else if (response && response.redirect) {
                window.location.href = response.redirect;

            } else if (response && response.reload) {
                window.location.reload();

            } else if (response && response.action) {
                this.setupFormAction(response.action, response.action_data);

            } else {
                this.setupFormUnexpectedError();

            }
        });
    }

    setupFormUnexpectedError() {
        const messageNode = document.createElement('span');
        messageNode.classList.add('general-error');
        messageNode.innerHTML = document.querySelector('meta[name="global_axios_validator_error"]').content;
        this.element.appendChild(messageNode);
        this.element.classList.remove('loading');
    }

    setupFormGeneralSuccess(success) {
        const messageNode = document.createElement('span');
        messageNode.classList.add('general-success');
        messageNode.innerHTML = success;
        this.element.appendChild(messageNode);
        this.element.classList.remove('loading');
    }

    setupFormGeneralError(error) {
        const messageNode = document.createElement('span');
        messageNode.classList.add('general-error');
        messageNode.innerHTML = error;
        this.element.appendChild(messageNode);
        this.element.classList.remove('loading');
    }

    setupFormErrors(errors) {
        let topMostError = null;

        for (let i = 0, keys = Object.keys(errors), ii = keys.length; i < ii; i++) {
            const input = this.element.querySelector('[name=' + keys[i] + ']');

            if (input) {
                input.classList.add('invalid');

                const messageNode = document.createElement('span');
                messageNode.classList.add('input-error');
                messageNode.innerHTML = errors[keys[i]];
                input.parentNode.insertBefore(messageNode, input.nextSibling);

                if (!topMostError) {
                    topMostError = input;
                } else {
                    if (topMostError.getBoundingClientRect().top > input.getBoundingClientRect().top) {
                        topMostError = input;
                    }
                }
            }
        }

        this.element.classList.remove('loading');

        // if (topMostError) {
        //     let toScrollElement = window;
        //     const openedModal = document.querySelector('.modal-identification.modal-show');
        //
        //     if (openedModal) {
        //         toScrollElement = openedModal.querySelector('.modal-scroll-container');
        //     }
        //
        //     toScrollElement.scroll({
        //         top: topMostError.getBoundingClientRect().top,
        //         behavior: 'smooth',
        //     });
        // }
    }

    removeFormNotes() {
        const generalErrors = this.element.querySelectorAll('.general-error');
        for (const el of generalErrors) {
            if (!el.classList.contains('axios-no-remove')) {
                el.remove();
            }
        }

        const generalSuccess = this.element.querySelectorAll('.general-success');
        for (const el of generalSuccess) {
            if (!el.classList.contains('axios-no-remove')) {
                el.remove();
            }
        }

        const fields = this.element.querySelectorAll('.input');
        for (const el of fields) {
            el.classList.remove('invalid');
        }

        const messageFields = this.element.querySelectorAll('.input-error');
        for (const el of messageFields) {
            el.remove();
        }
    }
}
