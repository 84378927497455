import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static get targets() {
        return ['collection', 'item', 'inner'];
    }

    updateFilter(event) {
        this.itemTargets.forEach((element) => {
                let hasItem = false;
                element.querySelectorAll('[data-field="query"]').forEach((field) => {
                    if (field.textContent.toLowerCase().includes(event.target.value.toLowerCase())) {
                        hasItem = true;
                    }
                });

                element.classList.toggle(
                    'is-hidden',
                    !hasItem,
                );
            }
        );

        this.innerTargets.forEach((element) => {
                let hasItem = false;
                element.querySelectorAll('[data-field="query"]').forEach((field) => {
                    if (field.textContent.toLowerCase().includes(event.target.value.toLowerCase())) {
                        hasItem = true;
                    }
                });

                element.classList.toggle(
                    'is-hidden',
                    !hasItem,
                );
            }
        );
    }
}