import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    connect() {
        for (const star of this.element.querySelectorAll('.rating-stars span')) {
            star.addEventListener('click', (e) => {
                e.preventDefault();

                this.element.querySelectorAll('.rating-stars span').forEach((star) => {
                    star.classList.remove('active');
                });

                let inp = this.element.querySelector('[name="rating"]');
                inp.value = star.getAttribute('data-stars');
                star.classList.add('active');

                return false;
            });
        }
    }
}