import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    connect() {
        this.currentLanguageCode = document.documentElement.lang.split('-')[0];
        this.element.addEventListener('submit', async (e) => {
            e.preventDefault();

            if (!this.element.querySelector('form')) {
                return;
            }

            const form = this.element.querySelector('form');

            if (form.classList.contains('loading')) {
                return;
            }

            form.classList.add('loading');

            const url = form.action;
            const method = form.method.toLowerCase();
            const data = new FormData(form);

            let response;

            try {
                if (method === 'get') {
                    response = await axios.get(url);
                } else if (method === 'post') {
                    response = await axios.post(url, data, {headers: {'Content-Type': 'multipart/form-data'}});
                }

                response = response.data.data;
            } catch (error) {
                response = error.response.data.data;
            }

            this.removeFormNotes(form);

            if (response && response.errors && Object.keys(response.errors).length) {
                this.setupFormErrors(response.errors, form);
            } else if (response && response.success) {
                this.setupFormGeneralSuccess(response.success, form);
            } else {
                this.setupFormUnexpectedError(form);
            }
        });
    }

    setupFormUnexpectedError(form) {
        const messageNode = document.createElement('span');
        messageNode.classList.add('general-error');
        messageNode.innerHTML = document.querySelector('meta[name="global_axios_validator_error"]').content;
        form.appendChild(messageNode);
        form.classList.remove('loading');
    }

    setupFormGeneralSuccess(success, form) {
        const messageNode = document.createElement('h2');
        messageNode.classList.add('general-success');
        messageNode.innerHTML = success;

        this.element.previousElementSibling.querySelector('.modal-title').remove();

        while (form.hasChildNodes()) {
            form.removeChild(form.firstChild);
        }

        form.appendChild(messageNode);
        form.classList.remove('loading');
    }

    setupFormErrors(errors, form) {

        let topMostError = null;
        for (let i = 0, keys = Object.keys(errors), ii = keys.length; i < ii; i++) {
            const input = form.querySelector('[name=' + keys[i] + ']');

            if (input) {
                input.classList.add('invalid');

                const messageNode = document.createElement('span');
                messageNode.classList.add('input-error');
                messageNode.innerHTML = errors[keys[i]];

                if (input.type == 'checkbox') {
                    input.parentNode.insertAdjacentElement('beforeend', messageNode);
                } else {
                    input.parentNode.insertBefore(messageNode, input.nextSibling);
                }

                if (!topMostError) {
                    topMostError = input;
                } else {
                    if (topMostError.getBoundingClientRect().top > input.getBoundingClientRect().top) {
                        topMostError = input;
                    }
                }
            }
        }

        form.classList.remove('loading');
    }

    removeFormNotes(form) {
        const generalErrors = form.querySelectorAll('.general-error');
        for (const el of generalErrors) {
            if (!el.classList.contains('axios-no-remove')) {
                el.remove();
            }
        }

        const generalSuccess = form.querySelectorAll('.general-success');
        for (const el of generalSuccess) {
            if (!el.classList.contains('axios-no-remove')) {
                el.remove();
            }
        }

        const fields = form.querySelectorAll('.input');
        for (const el of fields) {
            el.classList.remove('invalid');
        }

        const messageFields = form.querySelectorAll('.input-error');
        for (const el of messageFields) {
            el.remove();
        }
    }
}
