import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    connect() {
        const container = document.getElementById('shop-pickup-container');
        container.style.display = this.element.checked ? 'block' : 'none';

        this.element.addEventListener('click', () => {
            container.style.display = this.element.checked ? 'block' : 'none';
        });
    }
}